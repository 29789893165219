@tailwind base;

@tailwind components;

.MuiButtonBase-root,
.MuiButton-text,
.MuiButton-outlined,
.MuiTab-textColorPrimary {
  @apply !font-bold;
}

.MuiAlert-message {
  @apply !w-full;
}

@tailwind utilities;

.xterm-viewport {
  z-index: 10;
  background: transparent !important;
}